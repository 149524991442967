const messages = {
    g_new_moon:    '11111111111111110111111111111111101010101010111111111110111111111111111010111101111111011111111111101111111011110101111111111111101111111111111011111111010111111111110111110111111111111111011110111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111111111101111111011101111111101010101111111011110101111111111111101111111111111111011111101111111111101111111110101111111111111111011111111111111101111110111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    g_waxing_moon: '111111111111111101111111111111111010101010101111111111101111111111111110111111111111101111101111111111111111011111111110111111111111101010111111111111111101111111111101111111111111101111111101011111111111011111111111111110111111111111111101111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111101111111111111011111110111111110101010111111101111011110111111111111111101111101111111011111110111110111111111110111110111010111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    g_full_moon:   '1111111111111111011111111111111110101010101111111111111110111111101111111111111110111111101110111111111101111111111111101011110101101111111110111111111111110111111111111111101110111111101111110111111111101111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111101011111101111111111111011111101101011111110111101011111011010111111111010111101111111011111111111111010111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    g_waning_moon: '11111111111111110111111111111111101010101011111111111111101111111011111111111111101111111111101111011111111111111110111111111111111010101111011101111111110111111111110111111111111110111111110111111111101111111111110111111111111011111101111111111111111111111111111111100000000000000000000000000000000000000000000000000000000000001111111111111111011111111111111110101010101111110111111111111101101111111111111011101010111111101011110111111111111111011111111111111110111101111111111111011111111110111111111111101111111111110111111111101110111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',

    b_new_moon:    '11111111111111110111111111111111101010101010111111111110111111111111111011111111111110111111010111111110111111111110101111011011111111111111110111111101111111111111110111111101101111101111111110111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111111111101111111011101111111101010101111111011110101111111111111101111111111111111011111101111111111101111111110101111111111111111011111111111111101111110111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    b_waxing_moon: '11111111111111110111111111111111101010101010111111111110111111111111111010111011111111110111111010111101111011110111111111101111111111111011111111110111111111111101111111111111111011111101111101111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111101111111111111011111110111111110101010111111101111011110111111111111111101111101111111011111110111110111111111110111110111010111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    b_full_moon:   '1111111111111111011111111111111110101010101111111111111110111111101111111111111110111111111110111110111111111111111101101111111111111010111101111111111111101111111111110111111111111101101111111111111110111101111111111111111011101111111011111111111111111111111111111111000000000000000000000000000000000000000000000000000000000000011111111111111110111111111111111101010101011111101011111101111111111111011111101101011111110111101011111011010111111111010111101111111011111111111111010111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
    b_waning_moon: '1111111111111111011111111111111110101010101111111111111110111111101111111111111110111111101111110111111111101110111111111111101111010111111111111111011111111111101111111111110111101111111111110111111111111101101011111101111111111111111111111111111111100000000000000000000000000000000000000000000000000000000000001111111111111111011111111111111110101010101111110111111111111101101111111111111011101010111111101011110111111111111111011111111111111110111101111111111111011111111110111111111111101111111111110111111111101110111111111111111111111111111111110000000000000000000000000000000000000000000000000000000000000',
};

function transmit(canvas: HTMLCanvasElement, message: string, delay: number = 1500) {
    let index = 0;

    const gl = canvas.getContext('webgl')!;

    let raf: number = -1;

    function frame() {
        let bit = message[index] === '1';
        index = (index + 1) % message.length;

        console.log(bit);

        if (bit) {
            gl.clearColor(1.0, 1.0, 1.0, 1.0);
        } else {
            gl.clearColor(0.0, 0.0, 0.0, 1.0);
        }

        gl.clear(gl.COLOR_BUFFER_BIT);

        raf = requestAnimationFrame(frame);
    }

    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    const timeout = setTimeout(() => {
        raf = requestAnimationFrame(frame);
    }, delay);

    return () => {
        clearTimeout(timeout);
        cancelAnimationFrame(raf);
    };
}

let stopTransmission: (() => void) | undefined = undefined;

function start(message: string) {
    if (stopTransmission) {
        return;
    }

    const container = document.body.querySelector('.canvas-container')!;
    const canvas = container.querySelector('canvas') as HTMLCanvasElement;
    stopTransmission = transmit(canvas, message);
    container.classList.add('active');
}

function stop() {
    const container = document.body.querySelector('.canvas-container')!;
    if (stopTransmission) stopTransmission();
    stopTransmission = undefined;
    container.classList.remove('active');
}

document.body.querySelectorAll('.button').forEach(button => {
    const message_name = button.getAttribute('data-message')!;

    button.addEventListener('click', () => {
        const prefix = document.body.classList.contains('purist') ? 'b' : 'g';
        start(messages[`${prefix}_${message_name}`]);
    });
});

document.body.querySelector('.close-button')!.addEventListener('click', () => {
    stop();
});

document.body.querySelectorAll('input[type=radio]').forEach(rawElem => {
    const elem = rawElem as HTMLInputElement;

    if (elem.name !== 'purist-mode') {
        return;
    }

    elem.addEventListener('change', () => {
        if (elem.value === 'yes') {
            document.body.classList.add('purist');
        } else {
            document.body.classList.remove('purist');
        }
    });
});
